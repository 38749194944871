<template>
  <b-form>
    <b-form-group>
      <b-form-group label="Название">
        <b-form-input v-model="form.name" placeholder="Номер" :state="formErrors.name"/>
      </b-form-group>
    </b-form-group>
    <div>
      <div>
        <b-button variant="primary" size="sm" class="m-2" @click="removeVerticalRange">&lt;</b-button>
        <b-button variant="primary" size="sm" class="m-2" @click="addVerticalRange">&gt;</b-button>
      </div>
      <div class="d-flex">
        <div class="m-1 bus">
          <div v-for="(seatRow, index) in form.seats" :key="index" class="seat-row" >
            <div v-for="(seat, seatIndex) in seatRow" :key="index + '_' + seatIndex" class="seat" :class="{'is-passenger': seat.isPassenger, 'is-driver': seat.isDriver}">
              <b-dropdown ref="dropdown" size="sm" variant="link" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <div class="seat" :class="{'is-passenger': seat.isPassenger, 'is-driver': seat.isDriver}">
                    <span v-if="seat.isDriver" style="color: #fff">В</span>
                    <span>{{seat.number}}</span>
                  </div>
                </template>
                <b-dropdown-item @click="setDriver(index, seatIndex)">Водитель</b-dropdown-item>
                <b-dropdown-item @click="clearSeat(index, seatIndex)">Очистить</b-dropdown-item>
                <b-dropdown-form @submit.prevent="setPassenger(index, seatIndex, seatNumber)">
                  <label for="dropdown-form-seat-number">Пассажир</label>
                  <b-form-input id="dropdown-form-seat-number" v-model="seatNumber" size="sm" placeholder="Номер"></b-form-input>
                  <b-button class="mt-3" size="sm" variant="primary" @click="setPassenger(index, seatIndex, seatNumber)">Добавить</b-button>
                </b-dropdown-form>
              </b-dropdown>
            </div>
          </div>
        </div>
        <div>
          <b-button variant="primary" class="d-block m-2" size="sm" @click="removeHorizontalRange">&and;</b-button>
          <b-button variant="primary" class="d-block m-2" size="sm" @click="addHorizontalRange">&or;</b-button>
        </div>
      </div>
    </div>
    <div class="text-right mt-4">
      <b-button :variant="btnVariant" @click="onSave">{{ btnTitle }}</b-button>
    </div>
  </b-form>
</template>

<script>
import validate from "@/utils/formValidator";
import BUS_SCHEMA from "@/forms/BUS_SCHEMA";

const SCHEMA_INIT_FORM = () => ({

})

export default {
  name: "Form",
  props: {
    init: { required: false, default: () => SCHEMA_INIT_FORM() },
    btnVariant: { required: true },
    btnTitle: { required: true }
  },
  data() {
    return {
      form: this.init,
      seatNumber: null,
      errors: {}
    }
  },
  watch: {
    form: {
      handler: function () {
        this.validateForm();
      },
      deep: true
    }
  },
  computed: {
    formErrors: function () {
      return Object.fromEntries(Object.entries(this.errors).map((item) => [item[0], !item[1]]))
    }
  },
  methods: {
    setDefaultForm() {
      let seats = [
          [
              {
                number:	null,
                isPassenger: false,
                isDriver:	false
              },
              {
                number:	null,
                isPassenger: false,
                isDriver:	false
              },
              {
                number:	null,
                isPassenger: false,
                isDriver:	false
              }
          ],
          [
              {
                number:	null,
                isPassenger: false,
                isDriver:	false
              },
              {
                number:	null,
                isPassenger: false,
                isDriver:	false
              },
              {
                number:	null,
                isPassenger: false,
                isDriver:	false
              }
          ],
          [
            {
              number:	null,
              isPassenger: false,
              isDriver:	false
            },
            {
              number:	null,
              isPassenger: false,
              isDriver:	false
            },
            {
              number:	null,
              isPassenger: false,
              isDriver:	false
            }
          ]
      ]

      this.form = { ...this.form, seats }
    },
    generateSeatRow(columnCounts) {
      let row = [

      ]

      for (let i = 0; i < columnCounts; ++i) {
        row.push({
          number:	null,
          isPassenger:	false,
          isDriver:	false
        })
      }

      return row
    },
    addVerticalRange() {
      let seats = JSON.parse(JSON.stringify(this.form.seats))

      seats.map(row => row.push({
        number:	null,
        isPassenger:	false,
        isDriver:	false
      }))

      this.form.seats = seats
    },
    removeVerticalRange() {

      if (this.form.seats[0].length <= 1)
        return

      let seats = JSON.parse(JSON.stringify(this.form.seats))

      seats = seats.map(row => {
        return row.slice(0, row.length - 1)
      });

      this.form.seats = seats
    },
    addHorizontalRange() {
      let seats = JSON.parse(JSON.stringify(this.form.seats))

      seats.push(this.generateSeatRow(seats[0].length))

      this.form.seats = seats
    },
    removeHorizontalRange() {
      if (this.form.seats.length <= 1)
        return

      let seats = JSON.parse(JSON.stringify(this.form.seats))

      seats = seats.splice(0,seats.length - 1);

      this.form.seats = seats
    },
    setDriver(i,j) {
      let seats = JSON.parse(JSON.stringify(this.form.seats))
      seats = seats.map(row => row.map(column => column.isDriver ? {...column, isDriver: false} : column))
      seats[i][j] = {
        number:	null,
        isPassenger:	false,
        isDriver:	true
      }
      this.form.seats = seats
    },
    setPassenger(i,j, number) {
      if (!number)
        return

      let seats = JSON.parse(JSON.stringify(this.form.seats))
      seats[i][j] = {
        number:	number,
        isPassenger:	true,
        isDriver:	false
      }
      this.form.seats = seats
      this.seatNumber = null;
    },
    clearSeat(i,j) {
      let seats = JSON.parse(JSON.stringify(this.form.seats))
      seats[i][j] = {
        number:	null,
        isPassenger:	false,
        isDriver:	false
      }
      this.form.seats = seats
    },
    edit() {
      this.$bvToast.show('edit-toast');
    },
    show() {
    },
    fillForm(form) {
        this.form = {...form}
    },
    resetForm() {
      Object.assign(this.form, SCHEMA_INIT_FORM())
      this.errors = {}
    },
    validateForm() {
      this.errors = validate(this.form, BUS_SCHEMA)
      return Object.keys(this.errors).length === 0
    },
    onSave() {
      if (!this.validateForm())
        return

      let form = { ...this.form }
      delete form.seatsCount

      this.$emit('onSave', form);
    }
  }
}
</script>

<style scoped>
  .is-driver {
    background: #464646;
    border-color: #464646;
  }

  .is-passenger {
    background: #ebebeb;
    border-color: #ebebeb;
  }

  .seat-row {
    display: flex;
  }
  .seat {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid #eee;
    border-radius: 5px;
    margin: 5px;
    width: 25px;
    height: 25px;
    text-align: center;
  }
  .seat:hover {
    background-color: #858585;
    color: #fff;
  }
  .bus{
    border: 1px solid #eee;
    border-radius: 8px;
  }
</style>